import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import { getFlatpickrLocale } from '../shared/flatpickr_locale'

export default class extends Controller {
  connect () {
    const locale = getFlatpickrLocale()
    const pickerType = this.data.get('picker-type') || 'date'

    this.datepicker_instance = flatpickr(this.element, this._picker_options(pickerType, locale))
  }

  disconnect () {
    const dateValue = this.element.value
    this.datepicker_instance.destroy()
    this.element.value = dateValue
  }

  _picker_options (pickerType, locale) {
    switch (pickerType) {
      case 'date':
        return { dateFormat: 'd-m-Y', locale, disableMobile: true }
      case 'datetime':
        return { dateFormat: 'd-m-Y H:i', enableTime: true, time_24hr: true, locale, disableMobile: true }
    }
  }
}

import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller {
  connect () {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      ghostClass: 'sortable-ghost',
      onEnd: this.onEnd.bind(this)
    })
  }

  disconnect () {
    this.sortable.destroy()
  }

  onEnd (event) {
    const item = event.item
    const itemId = item.dataset.id
    const newPosition = event.newIndex + 1
    const url = this.data.get('url').replace('id', itemId)

    patch(url, {
      query: {
        position: newPosition
      },
      responseKind: 'turbo-stream'
    })
  }
}

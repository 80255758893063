import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['fetchFrom', 'child', 'departmentDocumentCategory', 'childDocumentCategory']

  fire () {
    if (this.fetchFromTarget.value === 'department') {
      this.childTarget.value = ''
      this.childDocumentCategoryTarget.value = ''
      get('/edialog/outgoing_messages/documents', {
        query: {
          type: this.fetchFromTarget.value,
          document_category_id: this.departmentDocumentCategoryTarget.value
        },
        responseKind: 'turbo-stream'
      })
    } else if (this.fetchFromTarget.value === 'child') {
      this.departmentDocumentCategoryTarget.value = ''
      get('/edialog/outgoing_messages/documents', {
        query: {
          type: this.fetchFromTarget.value,
          child_id: this.childTarget.value,
          document_category_id: this.childDocumentCategoryTarget.value
        },
        responseKind: 'turbo-stream'
      })
    }
  }
}
